.containerModeration {
  width: 800px;
  margin: auto;
  border: 2px solid #c2c2c2;
  border-radius: 12px;
  height: 900px;
  box-shadow: 10px 10px 5px #c2c2c2;
}
.ListeModeration {
  width: 80%;
  height: 900px;
  margin: auto;
  overflow-x: auto;
}

.btnAgrandir,
.btnSupprimer {
  border-radius: 12px;
  background-color: white;
  color: black;
  margin-top: 15px;
}

.alert {
  box-shadow: 10px 10px 5px #c2c2c2;
  border-radius: 10px;
  background-color: white;
}

.textNoir {
  color: black;
  font-weight: 400;
}
.textVerte {
  color: green;
  font-weight: 400;
}
.textGris {
  color: #9f9f9f;
}
hr {
  margin-top: 0;
  margin-bottom: 30px;
}

.cf:before,
.cf:after {
  content: '';
  display: table;
}
.cf:after {
  clear: both;
}
.droite {
  float: right;
}

.oModal {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
}

.oModal:target {
  opacity: 1;
  pointer-events: auto;
}

.oModal:target > div {
  margin: 10% auto;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.oModal > div {
  max-width: 800px;
  position: relative;
  margin: 6% auto;
  padding: 8px 8px 8px 8px;
  border-radius: 5px;
  background: #eee;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.oModal > div header,
.oModal > div footer {
  border-bottom: 1px solid #e7e7e7;
  border-radius: 5px 5px 0 0;
}
.oModal .footer {
  border: none;
  border-top: 1px solid #e7e7e7;
  border-radius: 0 0 5px 5px;
}

.oModal > div h2 {
  margin: 0;
}

.oModal > div .btn {
  float: right;
}

.oModal > div section,
.oModal > div > header,
.oModal > div > footer {
  padding: 15px;
}

section {
  color: black;
  font-size: 30px;
}
.logoDIV {
  display: flex;
}

.logoDIV img {
  width: 200px;
  margin-left: 200px;
}

.logoDIV .textGris {
  width: 232px;
}

.Qpos {
  margin-top: 50px;
}
