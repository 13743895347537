.conatiner {
  max-width: 100%;
  width: 100%;
  margin: auto;
  height: 100vh;
  border: solid;
}
.container1 {
  display: flex;
}
.contenuVmix {
  width: 90%;
  height: 75vh;
  background-color: blue;
}
.contenuChat {
  width: 25%;
  height: 75vh;
}
.contenuVmix iframe {
  height: 100%;
  width: 100%;
}

.container2 {
  display: flex;
  margin-top: 4vh;
}

.contenuClic {
  display: flex;
  width: 90%;

  background-color: white;

  justify-content: center;
}
.contenuClic iframe {
  height: 100%;
}

.contenuChatsend {
  width: 25%;
}

@media screen and (max-width: 900px) {
  .container1,
  .container2 {
    display: block;
  }
  .contenuVmix,
  .contenuChat,
  .contenuClic,
  .contenuChatsend {
    width: 100%;
  }
}
