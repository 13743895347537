.containerChat {
  width: 18%;
  height: 400px;
  background-color: #f38f00;
  margin: auto;
  position: fixed;
  bottom: 3px;
  z-index: 6;
}

form {
  margin: auto !important;
  width: 100%;
}
textarea {
  width: 80%;
  height: 150px;
  margin-left: 9% !important;
  box-shadow: 5px 5px 5px #dd7227;
}

.btnValider {
  background-color: white; /* Green */
  border: none;
  color: black;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 60px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;
  width: 80%;
  margin-left: 10%;
  box-shadow: 5px 5px 5px #f38f00;
}

.btnValider:hover {
  background-color: white;
  color: black;
  border: 2px solid #f38f00;
}
.TitleQuestion {
  color: white;
  font-weight: bold;
  margin-left: 9% !important;
  font-size: 15px;
  margin-bottom: 10px;
}

.tiret {
  color: white !important;
  margin-left: 90%;
  margin-top: -20px;
  margin-bottom: -15px;
  cursor: pointer;
}
.tiret a {
  font-size: 50px;

  margin-top: 0px;
}

.vignetteQO {
  position: absolute;
  bottom: 5%;
  left: 1%;
  z-index: 4;
}

.vignetteQOimg {
  width: 20%;
}

label {
  display: inline !important;
}

.vignetteQOimg {
  width: 20%;
}

@media screen and (max-width: 1440px) {
  .vignetteQOimg {
    width: 20%;
  }
}

@media screen and (max-width: 1280px) {
  .vignetteQOimg {
    width: 15%;
  }
}

@media screen and (max-width: 800px) {
  .containerChat {
    width: 100%;
    height: 100%;
    background-color: #f38f00;
    margin: auto;
    position: fixed;
    bottom: 3px;
    right: 3px;
    z-index: 6;
  }
}
.pointer {
  cursor: pointer;
}
.disclemer {
  color: white;
  font-size: 14px;
  padding: 40px;
  margin-bottom: 5px;
}
