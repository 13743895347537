.textQuiz {
  color: black;
}

.fond {
  margin-top: 10px;
  background-color: white;
  height: 300px;
  width: 80%;
  margin-left: 10%;
  border-color: aqua;
  padding: 20px;
  border-radius: 10px;
}

button {
  background-color: #eba63f;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  width: 100%;
}

.button {
  background-color: #f38f00;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  width: 100%;
}
.button:hover {
  background-color: #dd7227;
  color: white;
  text-decoration: none;
}

.containerQuiz {
  height: 800px;
}
