.container-fluid {
  max-width: 1024px;
}
.borderClass {
  display: flex;
  justify-content: center;
  background-size: cover;

  background-repeat: no-repeat;
  height: 419px;
  width: 100%;
  padding-right: 0px !important;
  padding-left: 0px !important;
  background-color: #ededed;
}
.stheader {
  padding-right: 0px !important;
  padding-left: 0px !important;
  background-color: #fff2e9;
}
.stfooter {
  padding-right: 0px !important;
  padding-left: 0px !important;
  background-color: #ededed;
}

.VideoClass {
  z-index: 1;
  height: 420px;
  background-color: #ededed;
}

@media (min-width: 576px) {
  .col-sm-3 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .col-sm-6 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media screen and (max-width: 800px) {
  .VideoClass {
    z-index: 1;
    height: 250px !important;
    display: flex;
    justify-content: center;
  }
  .borderClass iframe {
    width: 100% !important;
  }
  .borderClass {
    display: flex;
    justify-content: center !important;
    background-color: black !important;
  }
}
.VideoClass {
  z-index: 1;
  /* position: relative; */
  width: 100%;
  height: 62vh;
  padding-right: 0px !important;
  padding-left: 0px !important;
  /* padding-top: 1px !important;*/
}

.containerIncorrect {
  margin-top: 30px !important;
  width: 38%;
  margin: auto;
  background-color: #eeecec;
  padding: 20px;
}
.AccesRefuse {
  font-size: 24px;
  font-weight: bold;
  color: #ff0000;
  text-align: center;
}
.hr {
  background: #ff0000;
  height: 1px;
}

.textIncorrect {
  font-size: 18px;
  font-weight: bold;
  color: black;
  height: 300px;
  margin: 10px;
}
.textIncorrect .first {
  margin-bottom: 20px;
}

.loadingreact {
  margin: auto;
  width: 100%;
  height: 500px;
  margin-left: 40vw;
  margin-top: 10%;
}
