.btn1 {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
}

.btn2 {
  background-color: #f44336; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.container {
  max-width: 1024px;
  margin: auto;
}
.QuizInteractif {
  border: thick Solid #32a1ce;
  padding: 10px;
  margin-top: 20px;
}
span {
  color: #1e59da;
  font-size: large;
}

@media screen and (max-width: 800px) {
  .btn1 {
    background-color: #4caf50; /* Green */
    border: none;
    color: white;
    padding: 2px 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
  }

  .btn2 {
    background-color: #f44336; /* Green */
    border: none;
    color: white;
    padding: 2px 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 10px;
  }
}
