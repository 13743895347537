.Lien {
  color: #007cba;
  font-size: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  z-index: 2;
  margin-top: -9%;
  width: 100%;
}
.Lien > div {
  text-align: center;
  left: 10%;
}
.lien1,
.lien2 {
  width: 50%;
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
}
.lien2 {
  margin-left: 3%;
}
a {
  color: white;
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  .Lien {
    width: 90%;
  }

  .lien1,
  .lien2 {
    width: 50%;
    font-size: 8px;
    font-weight: bold;
    text-decoration: underline;
  }

  .play-btn {
    width: 100px;
    height: 100px;
    background: radial-gradient(
      rgba(7, 14, 31, 1) 60%,
      rgba(255, 255, 255, 1) 62%
    );
    border-radius: 50%;
    position: relative;
    display: block;
    margin: 100px auto;
    box-shadow: 0px 0px 25px 3px rgba(0, 124, 186, 1);
    position: absolute;
    top: -10% !important;
    left: 35% !important;
  }
}

.PlayerFullScreen {
  max-width: 100%;
  height: 100vh;
}

.play-btn {
  /*
  width: 100px;
  height: 100px;
  background: radial-gradient(
    rgba(255, 255, 255, 0.75) 60%,
    rgba(55, 179, 74, 1) 62%
  );
  border-radius: 50%;
  position: relative;
  display: block;
  margin: 100px auto;
  box-shadow: 0px 0px 25px 5px rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 20%;
  left: 43%;
  border: 0px;*/

  width: 100px;
  height: 100px;
  background: radial-gradient(hsla(0, 0%, 100%, 0.75) 60%, #007cba 62%);
  border-radius: 50%;
  /* position: relative; */
  /* display: block; */
  /* margin: 100px auto; */
  box-shadow: 0 0 25px 5px hsla(0, 0%, 100%, 0.75);
  position: absolute;
  bottom: 40%;
  left: 43%;
  border: 0;
}

@media screen and (max-width: 800px) {
  .play-btn {
    width: 100px;
    height: 100px;
    background: radial-gradient(
      rgba(255, 255, 255, 0.75) 60%,
      rgba(55, 179, 74, 1) 62%
    );
    border-radius: 50%;
    position: relative;
    display: block;
    margin: 100px auto;
    box-shadow: 0px 0px 25px 5px rgba(255, 255, 255, 0.75);
    position: absolute;
    top: 20%;
    left: 43%;
    border: 0px;
  }
}

/* triangle */
.play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-40%) translateY(-50%);
  transform: translateX(-40%) translateY(-50%);
  transform-origin: center center;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 25px solid rgba(0, 124, 186, 1);
  z-index: 100;
  -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

/* pulse wave */
.play-btn:before {
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate1 2s;
  animation: pulsate1 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.75);
  top: -30%;
  left: -30%;
  background: rgba(198, 16, 0, 0);
}

@-webkit-keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
      0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}

@keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
      0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}
.iframeIVI {
  z-index: 1;
  position: absolute;
  top: 0;
  margin-top: 0%;
  width: 100%;
  height: 100vh;
}
iframe {
  border-width: 0px;
}
.vide {
  position: absolute;
  right: 0%;
  width: 6%;
  background: transparent;
  height: 30px;
  cursor: pointer;
  bottom: 0px !important;
}

@media screen and (max-width: 1680px) {
  .vide {
    position: absolute;
    right: 0%;
    width: 7%;
    background: transparent;
    height: 30px;
    cursor: pointer;
    bottom: 0px !important;
  }
}

@media screen and (max-width: 1440px) {
  .vide {
    position: absolute;
    right: 0%;
    width: 8%;
    background: transparent;
    height: 30px;
    cursor: pointer;
    bottom: 0px !important;
  }
}

@media screen and (max-width: 1280px) {
  .vide {
    position: absolute;
    right: 0%;
    width: 9%;
    background: transparent;
    height: 30px;
    cursor: pointer;
    bottom: 0px !important;
  }
}

@media screen and (max-width: 1024px) {
  .vide {
    position: absolute;
    right: 0%;
    width: 11%;
    background: transparent;
    height: 30px;
    cursor: pointer;
    bottom: 0px !important;
  }
}

.ContainerHide {
  background-color: transparent;
  width: 200px;
  height: 200px;
  position: fixed;
  top: 0;
  right: 0;
}
